import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import ContactSection from "../components/common/ContactSection"
import InnerHeader from "../components/molecules/InnerHeader"
import { faqs, faqsPage } from "../content/Faqs"
import FaqsSection from "../components/common/FaqsSection"

const Faqs = () => {
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query

  return (
    <Layout>
      <Seo title="Faqs" />

      <InnerHeader
        title={faqsPage.title}
        description={faqsPage.description}
        googleReviewText={faqsPage.googleReviewText}
        isSearchBox={true}
        formDetails={faqsPage.formDetails}
        setSearchQuery={setSearchQuery} // Pass setSearchQuery to InnerHeader
      />

      <FaqsSection faqs={faqs} searchQuery={searchQuery} /> {/* Pass searchQuery to FaqsSection */}

      <ContactSection data={contactSection} sectionTitleAlignment={`center`} />
    </Layout>
  )
}

export default Faqs
